.mobileMenu {
  position: absolute;
  z-index: 9;
  right: 0px;
  top: -430px;
  height: 430px;
  width: 200px;
  background: #1e7b67;
  transition: all 0.3s ease 0s;
  overflow: hidden;
  text-align: center;
}

.mobileMenu.show {
  top: 0px;
}

.show-menu-button {
  /* position: fixed;
  right: 15px;
  top: 18px;
  z-index: 999999999; */
  cursor: pointer;
}

.mobileMenu .close {
  /* width: 40px;
  height: 40px; */
  /* line-height: 37px; */
  border: 1px solid #e7f7fe;
  border-radius: 4px;
  text-align: center;
  color: #e7f7fe;
  width: 100%;
  padding: 4px 0px;
  /* margin-right: auto;
  margin-left: 20px;
  margin-top: 20px; */
}

.mobileMenu .close:hover {
  color: #000000;
  border-color: #000000;
}

.responsivemenu {
  list-style: none;
  padding-left: 0;
}

.header .responsivemenu li a,
.header .responsivemenu li p {
  display: block;
  padding: 13px 30px;
  font-size: 16px;
  letter-spacing: 0;
  text-transform: capitalize;
  font-weight: 500;
  color: #fff;
}

.header .responsivemenu {
  display: unset;
}

.responsivemenu .card {
  border: none;
}

.responsivemenu .card-body {
  padding-top: 0;
}

.logo2 {
  padding: 20px;
  border-bottom: 1px solid #e7f7fe;
  padding-bottom: 13px;
}

.logo2 h2 a {
  color: #85aaba;
}

.responsivemenu .card-body li {
  background: transparent;
}

.responsivemenu li a {
  position: relative;
}

.responsivemenu li i {
  position: absolute;
  right: 20px;
  top: 17px;
}

.show-menu-button i {
  font-size: 30px;
  color: #fff;
}

.show-menu-button {
  display: none;
}

.header .responsivemenu li {
  padding: 0;
  border-bottom: 1px solid #e7f7fe;
}

.header .responsivemenu .card-body li {
  padding: 0;
  border-left: 1px solid #e7f7fe;
  border-right: 1px solid #e7f7fe;
}

.header .responsivemenu li p {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .mobileMenu {
    display: none;
  }
}

@media (max-width: 575px) {
  .mobileMenu {
    width: 100%;
    /* right: -200px;
    width: 200px; */
  }
}

@media (max-width: 992px) {
  .show-menu-button {
    display: block;
  }
}
