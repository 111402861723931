.header {
  background: transparent;
  position: fixed;
  z-index: 10;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 32px;
}

.scrolled {
  background-color: #1e7b67;
  /* background-color: #557644; */
  /* background-color: #bb828b; */
}

.header ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0px;
  text-align: center;
}

.header ul li {
  padding: 0px 15px;
  position: relative;
}

.header ul li a {
  color: #fff;
  text-decoration: none;
}

.header ul li a:hover {
  color: #000000;
}

.logo {
  min-width: 200px;
}

.logo h2 {
  margin: 0px;
}

.logo h2 a {
  font-size: 30px;
  font-family: "Great Vibes", cursive;
  color: #fff;
  margin: 0px;
}

.logo h2 a span {
  color: #fff;
  padding: 0px 10px;
}

.header-menu ul li {
  position: relative;
}

.header-menu .submenu {
  position: absolute;
  left: 18px;
  top: 140%;
  z-index: 999;
  width: 185px;
  padding: 10px 0px 15px;
  background: #fff;
  text-align: left;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden;
}

.header-menu ul > li:hover > .submenu {
  top: 100%;
  opacity: 1;
  visibility: visible;
}
.header-menu .submenu {
  display: unset;
}

.header-menu .submenu li {
  padding: 0px;
}
.header-menu .submenu li a {
  padding-left: 15px;
  display: block;
  color: #444;
  padding: 10px 10px;
  border-bottom: 1px solid #ebebeb;
}
.header-menu .submenu li:last-child a {
  border-bottom: none;
  padding-bottom: 0;
}
.header-menu .submenu li:first-child a {
  padding-top: 0;
}

@media (max-width: 1440px) {
  .header-menu .submenu2 {
    left: -120px;
  }
}
