.Gallery-section{
    background: rgba(178,201,211,.1);
}
.gallery-img {
    cursor: pointer;
    overflow: hidden;
}

.image-box img{
    object-fit: cover;
}
.gallery-img img {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
}

.gallery-img:hover img {
    -webkit-transform: scale(1.2) rotate(5deg);
    -ms-transform: scale(1.2) rotate(5deg);
    transform: scale(1.2) rotate(5deg);
}

.gallery-img:hover .image-box img{
 transform: none;
}