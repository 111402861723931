@import url("https://fonts.googleapis.com/css2?family=Cormorant+SC:wght@300;400;500;600;700&family=Cormorant:ital,wght@0,300..700;1,300..700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@import url("https://fonts.googleapis.com/css?family=Great+Vibes|Muli:300,400,500,600,700&display=swap");

* {
  font-family: "Montserrat", sans-serif;
}

h1,
h2,
h3 {
  font-family: "Great Vibes", cursive;
}

body {
  font-family: "Muli", sans-serif;
}

ul {
  list-style: none;
  margin-bottom: 0 !important;
}

a {
  text-decoration: none;
  transition: all 0.3s;
}

a:hover {
  text-decoration: none !important;
}

img {
  width: 100%;
}

.section-padding {
  padding: 120px 0;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1140px;
  }
}

.pb-70 {
  padding-bottom: 90px !important;
}

/* .App {
  text-align: left;
} */

/* .App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* .App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@media (max-width: 767px) {
  .section-padding {
    padding: 80px 0;
  }
}
